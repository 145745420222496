var leadService = new Vue({

    methods: {


        /**
         * insert
         *
         * @param id
         * @param successCallback
         * @param errorCallback
         */
        insert: function(body, successCallback, errorCallback) {
            this.$http.post(servicesConfig.apiHost + '/lead/insert', body, headersConfig).then(response => {
                successCallback(response.data.params);
            }, error => {
                errorCallback(error.data);
            });
        }


    }

});
